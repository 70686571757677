import "/src/assets/main.css";

import { createApp } from "/node_modules/.vite/deps/vue.js?v=cb7dd8d5";
import { createPinia } from "/node_modules/.vite/deps/pinia.js?v=cb7dd8d5";

import ElementPlus from "/node_modules/.vite/deps/element-plus.js?v=cb7dd8d5";
import "/node_modules/element-plus/dist/index.css";



import App from "/src/App.vue";
import router from "/src/router/index.js";

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(ElementPlus);

app.mount("#app");
